import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentRenderer from "../components/ContentRenderer"
import Footer from "../components/Footer"

import Envelope from "../images/envelope.svg"
import House from "../images/house.svg"

const AboutUs = ({ data }) => {
  const { contactAddress, contactEmail, contactPhone } = data.strapiGlobal

  return (
    <Layout>
      <Seo title="Contact" />
      <section className="nextier-contact-hero mb-8">
        <div className="blue-line-container">
          <div className="container nextier-contact-hero__inner">
            <div className="content-container">
              <h1 className="nextier-contact-hero__title">Get in Touch</h1>
              {/* <p className="nextier-contact-hero__text">
                lorem ipsum doloar sit amet
              </p> */}
              <div className="nextier-contact-hero__links">
                <img src={Envelope} className="icon" />
                <h6>
                  <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                </h6>
              </div>
              <div className="nextier-contact-hero__links">
                <img src={House} className="icon" />
                {/* <svg className="icon">
                  <use xlink:href="/assets/images/sprite.svg#house"></use>
                </svg> */}
                <h6 dangerouslySetInnerHTML={{ __html: contactAddress }} />
              </div>
            </div>
            <div className="nextier-contact-hero__form">
              <form
                name="contact"
                action="submit"
                method="POST"
                id="form"
                netlify={true}
              >
                <input type="hidden" name="form-name" value="contact" />

                <div className="nextier-contact-hero__input-container">
                  <label
                    for="name"
                    className="nextier-contact-hero__input-label"
                  >
                    First & Last Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Bob Ross"
                    required
                    className="nextier-contact-hero__input-field"
                  />
                </div>
                <div className="nextier-contact-hero__input-container">
                  <label
                    for="email"
                    className="nextier-contact-hero__input-label"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="you@company.com"
                    required
                    className="nextier-contact-hero__input-field"
                  />
                </div>
                <div className="nextier-contact-hero__input-container">
                  <label
                    for="phone"
                    className="nextier-contact-hero__input-label"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="555-123-4567"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    required
                    className="nextier-contact-hero__input-field"
                  />
                </div>
                <div className="nextier-contact-hero__message-container">
                  <label
                    for="message"
                    className="nextier-contact-hero__message-label"
                  >
                    Message
                  </label>
                  <textarea
                    rows="8"
                    name="message"
                    id="message"
                    placeholder="MESSAGE"
                    className="nextier-contact-hero__message-input"
                    required
                  ></textarea>
                </div>
                <div>
                  <button type="submit" className="primary-btn_alt btn-arrow">
                    <span>Send Message</span>
                  </button>
                </div>
                <p className="nextier-contact-hero__result" id="result"></p>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="contact-centered">
        <div className="blue-line-container">
          <div className="container contact-centered__inner">
            <div className="content-container">
              <h2 className="contact-centered__title">
                lorem ipsum dolor sit amet
              </h2>
              <p className="contact-centered__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque eu sit nec, lectus venenatis, ut vitae varius sit.
                Aenean id neque et egestas facilisi eget cras massa.
              </p>
              <a className="primary-btn btn-arrow">
                <span>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiGlobal {
      contactAddress
      contactEmail
      contactPhone
    }
  }
`

export default AboutUs
